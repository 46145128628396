import MyNavbar from "./components/MyNavbar/MyNavbar";
import MyFooter from "./components/MyFooter/MyFooter";
import ProtectedRoutes from "./components/ProtectedRoutes/ProtectedRoutes";
import React, { useContext, useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AppContext, AppContextProvider } from "./data/state";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./theme/variables.css";
import server from "./api/server";
import { User } from "./Models/User";

import Services from "./views/Services/Services";
import About from "./views/About/About";
import Reviews from "./views/Reviews/Reviews";
import Contact from "./views/Contact/Contact";
import SignIn from "./views/SignIn/SignIn";
import SignUp from "./views/SignUp/SignUp";
import ResetPassword from "./views/ResetPassword/ResetPassword";
import RecoverPassword from "./views/RecoverPassword/RecoverPassword";
import ListPayments from "./views/ListPayments/ListPayments";
import PaymentInformation from "./views/PaymentInformation/PaymentInformation";
import PaymentConfirmation from "./views/PaymentConfirmation/PaymentConfirmation";
import Home from "./views/Home/Home";
import Questionnaire from "./views/Questionnaire/Questionnaire";
import ListCompanies from "./views/ListCompanies/ListCompanies";
import Profile from "./views/Profile/Profile";
import Booking from "./views/Booking/Booking";
import ListTickets from "./views/ListTickets/ListTickets";
import ProcessPayment from "./views/ProcessPayment/ProcessPayment";
import ListZipCodes from "./views/ListZipCodes/ListZipCodes";
import CreateUser from "./views/CreateUser/CreateUser";
import CreateCompany from "./views/CreateCompany/CreateCompany";
import ListUsers from "./views/ListUsers/ListUsers";
import CompanyServices from "./views/CompanyServices/CompanyServices";
import CompanyService from "./views/CompanyService/CompanyService";
import ListServices from "./views/ListServices/ListServices";
import ListFeatures from "./views/ListFeatures/ListFeatures";
import ListValues from "./views/ListValues/ListValues";
import CreateService from "./views/CreateService/CreateService";
import CreateFeature from "./views/CreateFeature/CreateFeature";
import CreateValue from "./views/CreateValue/CreateValue";
import CreateZipCode from "./views/CreateZipCode/CreateZipCode";
import DetailTicket from "./views/DetailTicket/DetailTicket";
import UpdatePayment from "./views/UpdatePayment/UpdatePayment";
import UpdateStatus from "./views/UpdateStatus/UpdateStatus";
import ListLocation from "./views/ListLocation/ListLocation";
import LocationsZip from "./views/LocationsZip/LocationsZip";
import CreateLocation from "./views/CreateLocation/CreateLocation";
import CreateLocationZip from "./views/CreateLocationZip/CreateLocationZip";
import PaymentReq from "./views/PaymentReq/PaymentReq";
import CreatePaymentReq from "./views/CreatePaymentReq/CreatePaymentReq";
import ImportPrice from "./views/ImportPrice/ImportPrice";
import BookingReschedule from "./views/BookingReschedule/BookingReschedule";
import Balance from "./views/Balance/Balance";
import AddBalance from "./views/AddBalance/AddBalance";
import Parameters from "./views/Parameters/Parameters";
import Tools from "./views/Tools/Tools";
import UpdateValue from "./views/UpdateValue/UpdateValue";
import CreatePrintStatement from "./views/CreatePrintStatement/CreatePrintStatement";
import ListPriority from "./views/ListPriority/ListPriority";
import CreatePriority from "./views/CreatePriority/CreatePriority";
import LinkedService from "./views/LinkedService/LinkedService";
import CreateLinkedService from "./views/CreateLinkedService/CreateLinkedService";
import UpdateTimeUnit from "./views/UpdateTimeUnit/UpdateTimeUnit";

const hist = createBrowserHistory();

const UpKeepApp = () => {
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    if(state.token != undefined) {
      checkToken();
    }
  });

  const checkToken = async () => {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${state.token}`,
    };

    try {
      const response = await server.post(
        `/admin/auth/validate-token`,
        "",
        { headers: headers }
      );

      let data = response.data;

      if(data.statusCode == 200) {
        return;
      }
    } catch (error: any) {
      if (error.response.status == 401) {
        hist.push("/signin");
        window.location.reload();

        const tempUser: User ={
          name: "",
          email: "",
          phoneNumber: "",
          address: "",
        }
        await dispatch({
          type: 'set-is-loggedin',
          isLoggedin: false
        });
        await dispatch({
          type: 'set-user',
          user: tempUser
        });
        await dispatch({
          type: 'set-token',
          user: undefined
        });
        await dispatch({
          type: 'set-exist-ticket-id',
          existTicketId: false
        });
        await dispatch({
          type: 'set-ticket-id',
          ticketID: undefined
        });
        await dispatch({
          type: 'set-temp-password',
          isTempPassword: false,
        });
        await dispatch({
          type: 'set-permissions',
          permissions: [],
        });
      }
    };
  }

  return (
    <div className="wrapper">
      <Router history={hist}>
        {state.isLoggedin && <MyNavbar />}
        {state.isLoggedin ? (
          <div className="container-view">
          {
              !state.isTempPassword && state.isTempPassword !== undefined ? (
                <Switch>
                  <Route path="/home" exact component={Home} />
                  //tickets 1
                  <ProtectedRoutes component={DetailTicket} permissions={state.permissions} pathGroup={1} path="/detail-ticket" exact  />
                  <ProtectedRoutes component={ListTickets} permissions={state.permissions} pathGroup={1} path="/list-tickets"  />
                  <ProtectedRoutes component={BookingReschedule} permissions={state.permissions} pathGroup={1} path="/booking-reschedule" />
                  //company 2
                  <ProtectedRoutes path="/create-company" permissions={state.permissions} pathGroup={2} component={CreateCompany} />
                  <ProtectedRoutes path="/payment-request" permissions={state.permissions} pathGroup={2} component={PaymentReq} />
                  <ProtectedRoutes path="/list-payments" permissions={state.permissions} pathGroup={2} component={ListPayments} />
                  <ProtectedRoutes path="/create-print-statement" permissions={state.permissions} pathGroup={2} component={CreatePrintStatement} />
                  <ProtectedRoutes path="/create-payment-request" permissions={state.permissions} pathGroup={2} component={CreatePaymentReq} />
                  <ProtectedRoutes path="/create-payment" exact permissions={state.permissions} pathGroup={2} component={UpdatePayment} />
                  <ProtectedRoutes path="/list-locations" exact permissions={state.permissions} pathGroup={2} component={ListLocation} />
                  <ProtectedRoutes path="/list-companies" permissions={state.permissions} pathGroup={2} component={ListCompanies} />
                  <ProtectedRoutes path="/create-location" exact permissions={state.permissions} pathGroup={2} component={CreateLocation} />
                  <ProtectedRoutes path="/list-locations-zip" exact permissions={state.permissions} pathGroup={2} component={LocationsZip} />
                  <ProtectedRoutes path="/create-location-zip" permissions={state.permissions} pathGroup={2} component={CreateLocationZip} />
                  <ProtectedRoutes path="/import-price" permissions={state.permissions} pathGroup={2} component={ImportPrice} />
                  <ProtectedRoutes path="/time-unit" permissions={state.permissions} pathGroup={2} component={UpdateTimeUnit} />
                  //services 3
                  <ProtectedRoutes path="/create-service" exact permissions={state.permissions} pathGroup={3} component={CreateService} />
                  <ProtectedRoutes path="/list-services" exact permissions={state.permissions} pathGroup={3} component={ListServices} />
                  <ProtectedRoutes path="/company-services" exact permissions={state.permissions} pathGroup={3} component={CompanyServices} />
                  <ProtectedRoutes path="/company-service" exact permissions={state.permissions} pathGroup={3} component={CompanyService} />
                  <ProtectedRoutes path="/create-feature" exact permissions={state.permissions} pathGroup={3} component={CreateFeature} />
                  <ProtectedRoutes path="/list-features" exact permissions={state.permissions} pathGroup={3} component={ListFeatures} />
                  <ProtectedRoutes path="/list-values" exact permissions={state.permissions} pathGroup={3} component={ListValues} />
                  <ProtectedRoutes path="/create-value" exact permissions={state.permissions} pathGroup={3} component={CreateValue} />
                  <ProtectedRoutes path="/list-priority" exact permissions={state.permissions} pathGroup={3} component={ListPriority} />
                  <ProtectedRoutes path="/create-priority" exact permissions={state.permissions} pathGroup={3} component={CreatePriority} />
                  <ProtectedRoutes path="/linked-service" exact permissions={state.permissions} pathGroup={3} component={LinkedService} />
                  <ProtectedRoutes path="/create-linked-service" exact permissions={state.permissions} pathGroup={3} component={CreateLinkedService} />
                  //zip codes 4
                  <ProtectedRoutes path="/list-zip-codes" permissions={state.permissions} pathGroup={4} component={ListZipCodes} />
                  <ProtectedRoutes path="/create-zip-code" exact permissions={state.permissions} pathGroup={4} component={CreateZipCode} />
                  //users 5
                  <ProtectedRoutes path="/create-user" exact permissions={state.permissions} pathGroup={5} component={CreateUser} />
                  <ProtectedRoutes path="/list-users" exact permissions={state.permissions} pathGroup={5} component={ListUsers} />
                  //tools 6
                  <ProtectedRoutes path="/tools" exact permissions={state.permissions} pathGroup={6} component={Tools} />
                  <ProtectedRoutes path="/balance" exact permissions={state.permissions} pathGroup={6} component={Balance} />
                  <ProtectedRoutes path="/add-balance" exact permissions={state.permissions} pathGroup={6} component={AddBalance} />
                  <ProtectedRoutes path="/parameters" exact permissions={state.permissions} pathGroup={6} component={Parameters} />
                  <ProtectedRoutes path="/update-value" exact permissions={state.permissions} pathGroup={6} component={UpdateValue} />

                  <Route path="/about" component={About} />
                  <Route path="/booking" component={Booking} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/company-service" exact component={CompanyService} />
                  <Route path="/company-services" exact component={CompanyServices} />
                  <Route path="/home" exact component={Home} />
                  <Route path="/payment-info" component={PaymentInformation} />
                  <Route path="/questionnaire" component={Questionnaire} />
                  <Route path="/payment-confirm" component={PaymentConfirmation} />
                  <Route path="/process-payment" component={ProcessPayment} />
                  <Route path="/profile" component={Profile} />
                  <Route path="/recover-password" component={RecoverPassword} />
                  <Route path="/reviews" component={Reviews} />
                  <Route path="/services" component={Services} />
                  <Route path="/signin" component={SignIn} />
                  <Route path="/signup" component={SignUp} />
                  <Route path="/update-status" component={UpdateStatus} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route
                    path="/"
                    render={() => <Redirect to="/home" />}
                    exact={true}
                  />
                  <Redirect from="*" to="/" />
                </Switch>
              ) : (
                <Switch>
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="/signin" component={SignIn} />
                  <Redirect from="*" to="/reset-password" />
                </Switch>
              )
            }
          </div>
        ) : (
          <Switch>
            <Route path="/signin" component={SignIn} />
            <Route path="/recover-password" component={RecoverPassword} />
            <Redirect from="*" to="/signin" />
          </Switch>
        )}
        {state.isLoggedin && <MyFooter />}
      </Router>
    </div>
  );
};

const App = () => {
  return (
    <AppContextProvider>
      <UpKeepApp />
    </AppContextProvider>
  );
};
export default App;
