import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import server from "../../api/server";
import { AppContext } from '../../data/state';

const ProcessPayment: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  let history = useHistory();

  useEffect(()=>{
    if(state.existTicketId){
      makePaymentIntent();
    }

  }, [])

  const makePaymentIntent = async () => {
    setIsActive(true);
    //Start of payment intent
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.token}`
    }
    await server.post('/customer/payment', state.ticketID, { headers: headers })
      .then(async (res) => {
        const response = res.data;
        console.log(response);
        await dispatch({
          type: 'set-exist-payment-intent',
          existPaymentIntent: true
        })
        await dispatch({
          type: 'set-client-secret',
          clientSecret: response.data
        })
      })
      .catch(async (error) => {
        console.log(error);
        return;
      })
    //end of payment intent
    setIsActive(false);
    history.push('/payment-info');
  }

  return(
    <>
      {isActive && <Loading />}
    </>
  );
}

export default ProcessPayment;
